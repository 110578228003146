let mixin = {
    filters: {

    },
    methods: {
        // 图片前缀
        getImgSrc(img) {
            return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/images/" + img;
        },
        // 图片前缀
        ImgSrc(img) {
            return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/web/" + img;
        }
    }

}

export default mixin