import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/utils/get-page-title";
// import { getUserRouter } from '@/api/user'
// import Layout from '@/layout'

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  next();
  // return
  // set page title
  document.title = getPageTitle(to.meta.title);
  let url = decodeURIComponent(window.location.href);
  if (url.indexOf('token=') != -1) {
    let token1 = url.split('token=')[1];
    if (token1.indexOf('#') != -1) {
      var token = token1.split('#')[0]
    } else {
      var token = token1
    }
    // alert(token)
    localStorage.setItem('token', token)
  }
  next()
  return
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
