import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import './permission' // permission control
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from "echarts";
import axios from 'axios' //引入axios
import "./assets/css/reset.css"
import mixin from './mixins';
import yjlLoading from "@/components/yjl-loading/yjl-loading.vue";

Vue.prototype.$echarts = echarts; //挂载到Vue实例上面

// 全局组件挂载
Vue.component('yjl-loading', yjlLoading)
Vue.mixin(mixin)
Vue.use(Element)
Vue.prototype.getImgSrc = function (img) {
  return "https://hzyyb.oss-cn-shenzhen.aliyuncs.com/images/" + img;
}
Vue.prototype.$http = axios
Vue.config.productionTip = false;


new Vue({
  methods: {
    // base64 转为 file 
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
  },
  router,
  render: h => h(App)
}).$mount('#app')
