import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Yjldownload",
    component: () => import("../views/download/Yjldownload.vue"),
    meta: {
      title: "药聚力助手下载",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/Yjldownload",
    name: "Yjldownload",
    component: () => import("../views/download/Yjldownload.vue"),
    meta: {
      title: "药聚力助手下载",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/YjlUserdownload",
    name: "YjlUserdownload",
    component: () => import("../views/download/YjlUserdownload.vue"),
    meta: {
      title: "药聚力下载",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement/agreement.vue"),
    meta: {
      title: "协议中心",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/giveservice",
    name: "giveservice",
    component: () => import("../views/agreement/giveservice.vue"),
    meta: {
      title: "服务协议",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/agreement/privacy.vue"),
    meta: {
      title: "隐私协议",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/UserService",
    name: "UserService",
    component: () => import("../views/agreement/UserService.vue"),
    meta: {
      title: "药聚力服务协议",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/UserPrivacy",
    name: "UserPrivacy",
    component: () => import("../views/agreement/UserPrivacy.vue"),
    meta: {
      title: "药聚力隐私政策",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/UserPurchase",
    name: "UserPurchase",
    component: () => import("../views/agreement/UserPurchase.vue"),
    meta: {
      title: "药聚力网上采购协议",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/serve",
    name: "serve",
    component: () => import("../views/serve/serve.vue"),
    meta: {
      title: "帮助中心",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/open",
    name: "open",
    component: () => import("../views/serve/open.vue"),
    meta: {
      title: "药聚力白条开通指南",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("../views/serve/introduce.vue"),
    meta: {
      title: "购药安全",
      icon: "dashboard",
      affix: true
    }
  },
  {
    path: "/passed",
    name: "passed",
    component: () => import("../views/baoli/passed.vue"),
    meta: {
      title: "加载中...",
      icon: "dashboard",
      affix: true
    }
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
